import { AxiosResponse } from 'axios';
import { API } from 'utils/api';
import { AUTH_API_URL, USER_API_URL } from 'utils/constants';
import { ILoginRequest, ILoginResponse, IRestorePasswordRequest, IUserRequest, IUserResponse } from 'utils/types';
// import { IBulk } from '../types/bulk.interface';

class UserService {
	static login = (reqBody: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> =>
		API.post(AUTH_API_URL.LOGIN, reqBody);

	static logout = (): Promise<AxiosResponse<void>> => API.post(AUTH_API_URL.LOGOUT);

	static signup = (reqBody: IUserRequest): Promise<AxiosResponse<ILoginResponse>> =>
		API.post(AUTH_API_URL.REGISTER, reqBody);

	static getById = (id: string): Promise<AxiosResponse<IUserResponse>> => API.get(`${USER_API_URL.MAIN}/${id}`);

	static sendBulk = (
		reqBody: { [p: string]: any; bulkImage: string | null },
		id: string | null
	): Promise<AxiosResponse<IUserResponse>> => API.post(`${USER_API_URL.BULK}${id}`, reqBody);

	static resetPassword = (email: string): Promise<AxiosResponse<void>> =>
		API.get(`${AUTH_API_URL.RESET_PASSWORD}?email=${email}`);

	static restorePassword = (reqBody: IRestorePasswordRequest): Promise<AxiosResponse<void>> =>
		API.post(AUTH_API_URL.RESET_PASSWORD, reqBody);
}

export { UserService };
