/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-can.svg';
import { deleteScenario, postActiveScenario } from 'store/scenario';
import { getUserData } from 'store/user/actions';
import { COMMON_MESSAGE, SELECT_TYPE, STATUS_SELECT_OPTIONS, TOASTIFY_TYPES } from 'utils/constants';
import { capitalizeString, dateTransformer, getUpdateScenatioRoute, toastifyMessage } from 'utils/helpers';
import { useModal } from 'utils/hooks';
import { IBotScenario, IUseModal } from 'utils/types';
import { CustomButton } from '../CustomButton';
import { CustomIconButton } from '../CustomIconButton';
import { CustomModal } from '../CustomModal';
import { CustomSelect } from '../CustomSelect';
import styles from './index.module.scss';

interface ScenarioPreviewProps {
	data: IBotScenario;
	onChange: any;
	status: any;
	botStatus: any;
}

export const ScenarioPreview: FC<ScenarioPreviewProps> = ({ data, onChange, status, botStatus }) => {
	const [statusSelect, setStatusSelect] = useState(data.isActive ? SELECT_TYPE.ACTIVE : SELECT_TYPE.NOT_ACTIVE);
	const [isOpen, handleOpen, handleClose]: IUseModal = useModal();
	const dispatch = useDispatch();

	useEffect(() => {
		setStatusSelect(data.isActive ? SELECT_TYPE.ACTIVE : SELECT_TYPE.NOT_ACTIVE);
	}, [data.isActive]);

	const statusHandler = (value: any) => {
		if (!botStatus) {
			toastifyMessage(COMMON_MESSAGE.BOT_NOT_SETTED, TOASTIFY_TYPES.ERROR);
			return;
		}
		dispatch(postActiveScenario({ favoriteScenarioId: data._id }));
		dispatch(getUserData());
		setStatusSelect(value.target.value);
		setTimeout(() => {
			onChange(!status);
		}, 400);
	};

	const onDelete = (id: any) => {
		dispatch(deleteScenario(id));
		setTimeout(() => {
			onChange(!status);
		}, 400);
	};

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.main_info}>
					<Typography variant="h6" className={styles.main_info__text}>
						{capitalizeString(data.name)}
					</Typography>
					<Typography variant="body1" className={styles.main_info__text} sx={{ fontWeight: 400, fontSize: '14px' }}>
						{data.description}
					</Typography>
				</div>

				<div className={styles.date_info}>
					<Typography variant="caption">Created date: {dateTransformer(data.createdAt)}</Typography>
					<Typography variant="caption">Updated date: {dateTransformer(data.updatedAt)}</Typography>
				</div>

				<CustomSelect
					options={STATUS_SELECT_OPTIONS}
					value={statusSelect}
					onChange={value => statusHandler(value)}
					isDefault={false}
					className={styles.select__item}
				/>
				<div className={styles.buttons}>
					{!!data._id && (
						<Link to={getUpdateScenatioRoute(data._id)}>
							<CustomIconButton icon={<EditIcon />} />
						</Link>
					)}

					<CustomIconButton icon={<TrashIcon />} onClick={handleOpen} />
				</div>
			</div>
			<CustomModal isOpen={isOpen} onClose={handleClose}>
				<Box className={styles.modal__inner}>
					<Typography variant="h5">Are you sure you want to delete the scenario?</Typography>
					<Box className={styles.modal__buttons}>
						<CustomButton label="Yes" className={styles.modal__yes_button} onClick={() => onDelete(data._id)} />
						<CustomButton label="No" className={styles.modal__no_button} onClick={handleClose} />
					</Box>
				</Box>
			</CustomModal>
		</>
	);
};
