export const COMMON_FIELD_NAMES = {
	// auth
	EMAIL: 'email',
	PASSWORD: 'password',
	PASSWORD_NEW: 'newPassword',
	PASSWORD_CONFIRM: 'confirmPassword',

	// settings
	BOT_NAME: 'botName',
	BOT_TOKEN: 'botToken',

	// user
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',

	// scenario
	SCENARIO_NAME: 'name',
	SCENARIO_DESCRIPTION: 'description',
	SCENARIO_BODY: 'body',

	// scenario item
	SCENARIO_ITEM_TEXT: 'text',
	SCENARIO_ITEM_PHOTO: 'photo',
	SCENARIO_ITEM_DELAY: 'delay',
	SCENARIO_ITEM_OPTIONS: 'options',

	// scenario option
	SCENARIO_OPTION_TEXT: 'buttonText',
	SCENARIO_OPTION_SCENARIO_NAME: 'scenarioName',
	SCENARIO_OPTION_URL: 'url',
	SCENARIO_OPTION_IS_NEW: 'isNew',
	SCENARIO_OPTION_NEXT_SCENARIO: 'nextScenario',
	SCENARIO_OPTION_PARENT: 'parentId',
	SCENARIO_OPTION_LEVEL: 'level',
};
