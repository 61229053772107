import { FC, ReactNode } from 'react';
import { Typography, useTheme } from '@mui/material';
import classnames from 'classnames';
import styles from './index.module.scss';

interface FieldWrapperProps {
	title: string;
	description?: string;
	children: ReactNode;
	isStepper?: boolean;
}

export const FieldWrapper: FC<FieldWrapperProps> = ({ children, title, description = '', isStepper = false }) => {
	const theme = useTheme();

	return (
		<div className={styles.content}>
			{!!title && (
				<Typography
					className={styles.title}
					variant={isStepper ? 'h4' : 'subtitle1'}
					align={isStepper ? 'center' : 'left'}
					sx={{ color: theme.vars.palette.text.primary }}
				>
					{title}
				</Typography>
			)}
			{!!description && (
				<Typography
					className={classnames(styles.subtitle, {
						[styles.subtitle_profile]: !isStepper,
					})}
					variant={isStepper ? 'body1' : 'body2'}
					align={isStepper ? 'center' : 'left'}
					sx={{ color: theme.vars.palette.text.secondary }}
				>
					{description}
				</Typography>
			)}
			{children}
		</div>
	);
};
