import { FC } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import styles from './index.module.scss';

export const Footer: FC = () => (
	<footer className={styles.footer}>
		<div className={styles.footer__content}>
			<Typography variant="caption" align="right" className={styles.copyrights}>
				(C) Copyright. {dayjs().format('YYYY')}
			</Typography>
		</div>
	</footer>
);
