import { AxiosResponse } from 'axios';
import { API } from 'utils/api';
import { SETTINGS_API_URL, VAULT_SERVICE_KEYS } from 'utils/constants';
import { IBotScenario } from 'utils/types';
import { VaultService } from './vault.service';

class SettingsService {
	private userId: string | null;

	constructor() {
		this.userId = VaultService.getItem<string>(VAULT_SERVICE_KEYS.USER_ID);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static postSettings = async (reqBody: any): Promise<AxiosResponse<IBotScenario>> => {
		const instance = new SettingsService();
		return API.post(`${SETTINGS_API_URL.MAIN}${instance.userId}`, reqBody);
	};
}

export { SettingsService };
