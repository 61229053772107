import { experimental_extendTheme as extendTheme } from '@mui/material';

export const theme = extendTheme({
	cssVarPrefix: '',
	colorSchemes: {
		light: {
			palette: {
				primary: {
					main: '#4A90E2',
				},
				text: {
					primary: '#333333',
					secondary: '#96969D',
				},
				background: {
					default: '#FFFFFF',
				},
				error: {
					main: '#FF2D55',
					light: '#FF2D55',
					dark: '#FF2D55',
				},
				white: '#FFFFFF',
				primaryButton: '#4A90E2',
				indigoPale: '#ACABCA',
				backgroundGrey: '#F2F2F2',
				disabledGrey: '#BBBBC3',
				inputGrey: '#E5E5EA',
				inputDisabled: '#D7D7DF',
				signalRed: '#FF2D55',
				signalOrange: '#FF6B00',
				signalGreen: '#27AE60',
				signalBlue: '#0094FF',
				signalYellow: '#FFCC00',
				cardPaleOrange: '#F1E4DA',
				cardPaleGreen: '#DDF1DA',
				cardPaleBlue: '#DAEBF1',
			},
		},
	},

	components: {
		MuiStepper: {
			styleOverrides: {
				vertical: {
					display: 'block',
					overflowY: 'auto',
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: () => ({
					color: '#fff',
				}),
			},
		},
		MuiStepContent: {
			styleOverrides: {
				root: () => ({
					marginLeft: '16px',
					paddingLeft: '22px',
					paddingBottom: '30px',
				}),
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				root: () => ({
					marginLeft: '16px',
				}),
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: ({ theme }) => ({
					'.MuiSlider-thumb': {
						width: '16px',
						height: '16px',
					},
					'.MuiSlider-thumb::after': {
						background: theme.vars.palette.background.default,
						width: '50%',
						height: '50%',
					},
					'.MuiSlider-rail': {
						background: theme.vars.palette.disabledGrey,
					},
					'.MuiSlider-markLabel': {
						color: theme.vars.palette.indigoPale,
						fontWeight: '800',
						fontSize: '12px',
						lineHeight: '18px',
					},
					'.MuiSlider-markLabelActive': {
						color: theme.vars.palette.primary.main,
					},
					'.MuiSlider-mark': {
						display: 'none',
					},
				}),
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: () => ({
					'&.MuiPaper-root ': {
						boxShadow: 'none',
						width: '100%',
					},
				}),
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'dropdown' },
					style: ({ theme }) => ({
						color: theme.vars.palette.primary.main,
						padding: '8px 16px',
						transition: theme.transitions.create('all', {
							duration: theme.transitions.duration.standard,
						}),
						'& .MuiButton-endIcon svg': {
							color: theme.vars.palette.text.primary,
							transition: theme.transitions.create('all', {
								duration: theme.transitions.duration.standard,
							}),
						},
						'&:disabled': {
							color: theme.vars.palette.inputDisabled,
							backgroundColor: theme.vars.palette.background.default,
							'& .MuiButton-endIcon svg': {
								color: theme.vars.palette.inputDisabled,
							},
						},
						'&:hover': {
							color: theme.vars.palette.primary.main,
							background: theme.vars.palette.backgroundGrey,
						},
					}),
				},
			],
			styleOverrides: {
				root: ({ theme }) => ({
					width: 'auto',
					minWidth: '170px',
					padding: '8px 16px',
					fontStyle: 'normal',
					fontWeight: '700',
					textTransform: 'unset',
					fontSize: '14px',
					lineHeight: '24px',
					position: 'relative',
					color: theme.vars.palette.white,
					background: theme.vars.palette.primary.main,
					border: `1px solid`,
					borderRadius: '20px',
					boxShadow: 'none',
					gap: '12px',
					alignItems: 'center',
					justifyContent: 'center',
					'&:hover': {
						color: theme.vars.palette.white,
						background: theme.vars.palette.primary.main,
						boxShadow: '0px 4px 6px -1px rgba(6, 6, 43, 0.1), 0px 2px 4px -2px rgba(6, 6, 43, 0.05)',
					},
					'&:active': {
						color: theme.vars.palette.white,
						borderColor: theme.vars.palette.indigoPale,
						background: theme.vars.palette.indigoPale,
						boxShadow: '0px 4px 6px -1px rgba(6, 6, 43, 0.1), 0px 2px 4px -2px rgba(6, 6, 43, 0.05)',
					},
					'&:disabled': {
						color: theme.vars.palette.white,
						borderColor: theme.vars.palette.white,
						background: theme.vars.palette.inputDisabled,
						'& .MuiButton-endIcon, & .MuiButton-startIcon': {
							'svg path': {
								fill: theme.vars.palette.white,
							},
						},
					},
					'& .MuiButton-endIcon, & .MuiButton-startIcon': {
						margin: 0,
						'svg path': {
							transition: theme.transitions.create('all', {
								duration: theme.transitions.duration.standard,
							}),
							fill: theme.vars.palette.white,
						},
					},
				}),
				text: ({ theme }) => ({
					minWidth: 'max-content',
					height: 'min-content',
					color: theme.vars.palette.primary.main,
					background: theme.vars.palette.white,
					borderColor: theme.vars.palette.white,
					'&:hover': {
						background: theme.vars.palette.backgroundGrey,
						boxShadow: 'none',
						color: theme.vars.palette.primary.main,
					},
					'&:active': {
						color: theme.vars.palette.indigoPale,
						background: theme.vars.palette.backgroundGrey,
					},
					'&:disabled': {
						color: theme.vars.palette.inputDisabled,
						borderColor: theme.vars.palette.white,
						background: theme.vars.palette.inputDisabled,
					},
				}),
				outlined: ({ theme }) => ({
					color: theme.vars.palette.primary.main,
					background: theme.vars.palette.white,
					'& .MuiButton-endIcon, & .MuiButton-startIcon': {
						'svg path': {
							fill: theme.vars.palette.primary.main,
						},
					},
					'&:hover': {
						color: theme.vars.palette.white,
						background: theme.vars.palette.primary.main,
						'& .MuiButton-endIcon, & .MuiButton-startIcon': {
							'svg path': {
								fill: theme.vars.palette.white,
							},
						},
					},
					'&:active': {
						color: theme.vars.palette.white,
						background: theme.vars.palette.indigoPale,
						'& .MuiButton-endIcon, & .MuiButton-startIcon': {
							'svg path': {
								fill: theme.vars.palette.white,
							},
						},
					},
					'&:disabled': {
						color: theme.vars.palette.white,
						borderColor: theme.vars.palette.inputDisabled,
						background: theme.vars.palette.inputDisabled,
						'& .MuiButton-endIcon, & .MuiButton-startIcon': {
							'svg path': {
								fill: theme.vars.palette.white,
							},
						},
					},
				}),
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: ({ theme }) => ({
					height: 'max-content',
					'&:hover > svg path': {
						fill: theme.vars.palette.primary.main,
					},
				}),
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						height: '100%',
						top: 0,
					},
				},
				input: ({ theme }) => ({
					fontSize: '16px',
					lineHeight: '42px',
					height: '100%',
					'&::placeholder': {
						color: theme.vars.palette.text.secondary,
					},
					'&:-webkit-autofill': {
						WebkitBoxShadow: `0 0 0 100px ${theme.vars.palette.white} inset`,
					},
				}),
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					display: 'block',
					color: theme.vars.palette.text.secondary,
					fontSize: '14px',
					lineHeight: '18px',
					marginBottom: '2px',
					'&.Mui-disabled': {
						color: theme.vars.palette.inputDisabled,
					},
					'&.Mui-error': {
						color: theme.vars.palette.signalRed,
					},
					'&.Mui-focused': {
						color: theme.vars.palette.text.secondary,
					},
				}),
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: theme.vars.palette.text.secondary,
					fontSize: '14px',
					'&.Mui-disabled': {
						color: theme.vars.palette.inputDisabled,
					},
					'&.Mui-error': {
						color: theme.vars.palette.signalRed,
					},
					'&.Mui-focused': {
						color: theme.vars.palette.text.secondary,
					},
				}),
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&.MuiOutlinedInput-input': {
						width: '100%',
						color: theme.vars.palette.text.primary,
					},
					'& legend > span': {
						display: 'none',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.vars.palette.inputGrey,
						padding: '0px',
					},
					'&.Mui-focused': {
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: theme.vars.palette.indigoPale,
						},
					},
					width: '100%',
					minHeight: '42px',
					fontSize: '16px',
					borderRadius: '20px',
					borderWidth: '1px',
					'& .MuiOutlinedInput-input': {
						padding: '0px 16px',
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.vars.palette.indigoPale,
					},
					'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.vars.palette.inputDisabled,
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.vars.palette.primary.main,
						borderWidth: 1,
					},
					':invalid, &.Mui-error .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.vars.palette.signalRed,
						fieldset: {
							borderColor: theme.vars.palette.signalRed,
						},
					},
					'& .MuiInputAdornment-root .MuiButtonBase-root': {
						'& path, stroke': {
							fill: theme.vars.palette.disabledGrey,
							stroke: theme.vars.palette.disabledGrey,
						},
						'&:hover stroke, &:hover path': {
							fill: theme.vars.palette.text.secondary,
							stroke: theme.vars.palette.text.secondary,
						},
					},
				}),
			},
		},
		MuiSelect: {
			styleOverrides: {
				standard: {
					width: '100%',
				},
				select: ({ theme }) => ({
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
					borderColor: theme.vars.palette.inputGrey,
					// padding: 0,
					'&.MuiInputBase-input': {
						paddingRight: 40,
					},
					'& > .MuiButtonBase-root': {
						padding: 0,
						display: 'flex',
						gap: 8,
						alignItems: 'center',

						'& > svg': {
							flex: '0 0 auto',
						},

						'& > span': {
							display: 'block',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						},
					},
					'&::placeholder': {
						fontSize: '16px',
						fontFamily: 'inherit',
					},
					'& .MuiListItem-root': {
						padding: 0,
					},
					'& > .MuiListItemButton-root': {
						'&:hover': {
							background: 'none',
						},
						'& .MuiTouchRipple-root': {
							display: 'none',
						},
					},
				}),
				icon: ({ theme }) => ({
					color: theme.vars.palette.text.primary,
					transition: theme.transitions.create('all', {
						duration: theme.transitions.duration.standard,
					}),
					right: '20px',
					marginLeft: '12px',
					top: 'calc(50% - 0.3em)',
				}),
				multiple: {
					'& > .MuiButtonBase-root': {
						display: 'inline-flex',
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: '16px',
					boxShadow: '0px 4px 6px -1px rgba(6, 6, 43, 0.1), 0px 2px 4px -2px rgba(6, 6, 43, 0.05)',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					minWidth: '160px',
					gap: '14px',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					boxSizing: 'border-box',
					zIndex: 9999,
				},
				paper: {
					maxWidth: 'none',
					width: '770px',
					borderRadius: '16px',
					boxShadow: '0px 10px 15px -3px rgba(6, 6, 43, 0.1), 0px 4px 6px rgba(6, 6, 43, 0.05)',
					margin: 0,
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					position: 'absolute',
					left: '15%',
					top: '30%',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: '48px 40px 40px',
					gap: '24px',
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: '24px 0',
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				root: {
					zIndex: 100000,
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderRadius: '16px',
					backgroundColor: theme.vars.palette.inputGrey,
				}),
				bar1Determinate: ({ theme }) => ({
					backgroundColor: theme.vars.palette.primary.main,
					borderRadius: '16px',
				}),
			},
		},
		MuiPagination: {
			styleOverrides: {
				root: {
					marginTop: '24px',
					display: 'flex',
					justifyContent: 'center',
					'& .MuiPagination-ul': {
						justifyContent: 'space-between',
						width: '96%',
					},
				},
			},
		},
		MuiPaginationItem: {
			styleOverrides: {
				root: ({ theme }) => ({
					minWidth: '30px',
					height: '42px',
					lineHeight: '42px',
					fontWeight: '700',
					padding: 0,
					'&.Mui-selected': {
						backgroundColor: theme.vars.palette.primary.main,
						color: theme.vars.palette.white,
					},
					'&.MuiPaginationItem-previousNext': {
						background: theme.vars.palette.primary.main,
						borderColor: 'transparent',
						maxWidth: '',
						minWidth: '203px',
						width: '100%',
						height: '42px',
						overflow: 'hidden',
						'&:hover': {
							color: theme.vars.palette.white,
							background: theme.vars.palette.primary.main,
						},
						'&:active': {
							color: theme.vars.palette.white,
							borderColor: theme.vars.palette.indigoPale,
							background: theme.vars.palette.indigoPale,
						},
						'&:disabled': {
							color: theme.vars.palette.white,
							borderColor: theme.vars.palette.white,
							background: theme.vars.palette.disabledGrey,
						},
						'& > .MuiButtonBase-root': {
							background: 'none',
							margin: 0,
							border: 'none',
							width: '100%',
							height: '100%',
							minWidth: 'auto',
							padding: '8px 56px',
							borderRadius: 'initial',
						},
						'&:last-child': {
							alignSelf: 'flex-start',
						},
					},
				}),
			},
		},
		MuiChip: {
			styleOverrides: {
				root: ({ theme }) => ({
					padding: '4px 12px',
					paddingRight: '6',
					gap: '4px',
					backgroundColor: theme.vars.palette.backgroundGrey,
					'&:hover': {
						backgroundColor: theme.vars.palette.inputGrey,
						'& .MuiChip-deleteIcon': {
							'& path, & stroke': {
								fill: theme.vars.palette.text.secondary,
								stroke: theme.vars.palette.text.secondary,
							},
						},
					},
					'& .MuiChip-label': {
						fontSize: '14px',
						padding: 0,
					},
					'& .MuiChip-deleteIcon': {
						width: '24px',
						height: '24px',
						margin: 0,
						objectFit: 'contain',
						'& path, & stroke': {
							fill: theme.vars.palette.disabledGrey,
							stroke: theme.vars.palette.disabledGrey,
						},
					},
				}),
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					width: '100%',
					position: 'relative',
					marginBottom: '0',
					height: '100%',
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					width: 'max-content',
					margin: '0',
					position: 'relative',
					alignItems: 'center',
					'&.Mui-error': {
						'&.checkbox_button': {
							borderColor: theme.vars.palette.signalRed,
						},
						'& .MuiCheckbox-root path': {
							fill: theme.vars.palette.signalRed,
						},
						'& .MuiRadio-root svg path': {
							fill: theme.vars.palette.signalRed,
						},
						'& .MuiFormControlLabel-label': {
							color: theme.vars.palette.signalRed,
						},
					},
					'& .MuiFormControlLabel-label': {
						display: 'flex',
						alignItems: 'center',
					},
					'& .MuiIcon-root': {
						width: 'auto',
						height: 'auto',
						fontSize: 'inherit',
						marginRight: '10px',
					},
					'& .MuiTypography-root': {
						fontSize: '14px',
						fontWeight: '400',
						lineHeight: '22px',
						userSelect: 'none',
					},
					'& .tooltip': {
						width: '14px',
						height: '14px',
						position: 'absolute',
						top: '4px',
						right: '4px',
						path: {
							transition: theme.transitions.create('all', {
								duration: theme.transitions.duration.standard,
							}),
						},
						':hover path': {
							fill: theme.vars.palette.primary.main,
						},
					},
					// checked
					'&.checked': {
						'.MuiIcon-root path': {
							fill: theme.vars.palette.primary.main,
						},
						'& .MuiTypography-root': {
							color: theme.vars.palette.primary.main,
						},
						'&.checkbox_button': {
							borderColor: theme.vars.palette.primary.main,
						},
					},
					// custom checkbox button variant
					'&.checkbox_button': {
						width: '100%',
						height: '100%',
						minHeight: '44px',
						padding: '8px 16px',
						borderRadius: '12px',
						border: '1px solid',
						borderColor: theme.vars.palette.indigoPale,
						'& .MuiCheckbox-root': {
							padding: 0,
							marginRight: '16px',
						},
					},
				}),
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: ({ theme }) => ({
					'& .MuiTypography-root': {
						color: theme.vars.palette.white,
						fontSize: '12px',
						fontWeight: '400',
					},
				}),
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				inputRoot: () => ({
					padding: '0 16px',
				}),
				popper: () => ({
					minWidth: 'fill-available !important',
					width: 'max-content !important',
				}),
			},
		},
	},

	typography: palette => ({
		fontFamily: 'AOK Buenos Aires, sans-serif',
		h1: {
			fontSize: '64px',
			lineHeight: '80px',
			fontWeight: '700',
		},
		h2: {
			fontSize: '48px',
			lineHeight: '64px',
			fontWeight: '700',
		},
		h3: {
			fontSize: '32px',
			lineHeight: '48px',
			fontWeight: '700',
			color: palette.primary.main,
		},
		h4: {
			fontSize: '24px',
			lineHeight: '36px',
			fontWeight: '700',
		},
		h5: {
			fontSize: '20px',
			lineHeight: '30px',
			fontWeight: '700',
		},
		h6: {
			fontSize: '18px',
			lineHeight: '28px',
			fontWeight: '700',
		},
		subtitle1: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: '600',
			color: palette.text.primary,
		},
		subtitle2: {
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: '600',
		},
		body1: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: '500',
		},
		body2: {
			fontSize: '14px',
			lineHeight: '22px',
			fontWeight: '500',
		},
		caption: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: '400',
			color: palette.text.primary,
		},
		overline: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: '700',
			letterSpacing: '1.2px',
		},
		button: {
			fontSize: '14px',
			lineHeight: '24px',
			fontWeight: '700',
			color: palette.primary.main,
			textTransform: 'initial',
		},
	}),
});
