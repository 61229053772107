import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AUTH_MESSAGE, TOASTIFY_TYPES, USER_MESSAGE, VAULT_SERVICE_KEYS } from 'utils/constants';
import { toastifyMessage } from 'utils/helpers';
import { UserService, VaultService } from 'utils/services';
import { IBulkResponse, ILoginRequest, ILoginResponse, IUserRequest } from 'utils/types';
import { IBulk } from '../../utils/types/bulk.interface';

export enum ACTION_TYPES {
	USER_LOADING = 'USER_LOADING',
	USER_GET_DATA = 'USER_GET_DATA',
	USER_SEND_BULK_MESSAGE = 'SEND_BULK_MESSAGE',
}

const setUserDataToStorage = (data: ILoginResponse): void => {
	VaultService.setItem(VAULT_SERVICE_KEYS.ACCESS_TOKEN, data.accessToken);
	VaultService.setItem(VAULT_SERVICE_KEYS.REFRESH_TOKEN, data.refreshToken);
	VaultService.setItem(VAULT_SERVICE_KEYS.USER_ID, data.userId);
};

export const changeUserIsLoading = (payload: boolean): AnyAction => ({ type: ACTION_TYPES.USER_LOADING, payload });

export const loginUser =
	(reqBody: ILoginRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeUserIsLoading(true));
			const response: AxiosResponse<ILoginResponse> = await UserService.login(reqBody);
			setUserDataToStorage(response.data);
			dispatch({ type: ACTION_TYPES.USER_GET_DATA, payload: response.data });
			toastifyMessage(AUTH_MESSAGE.LOGIN_SUCCESS, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			if (error.response.status === 401) {
				toastifyMessage(USER_MESSAGE.USER_NOT_FOUND, TOASTIFY_TYPES.ERROR);
				throw new Error(error);
			}
			if (error.response.status === 402) {
				toastifyMessage(USER_MESSAGE.PASSWORD_IS_WRONG, TOASTIFY_TYPES.ERROR);
				throw new Error(error);
			}
			if (error.response.status === 411) {
				toastifyMessage(USER_MESSAGE.USER_EXISTS, TOASTIFY_TYPES.ERROR);
				throw new Error(error);
			}
		} finally {
			dispatch(changeUserIsLoading(false));
		}
	};

export const logoutUser =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeUserIsLoading(true));
			await UserService.logout();
			dispatch({ type: ACTION_TYPES.USER_GET_DATA, payload: null });
			VaultService.clear();
			toastifyMessage(AUTH_MESSAGE.LOGOUT_SUCCESS, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			toastifyMessage(error?.response?.data, TOASTIFY_TYPES.ERROR);
		} finally {
			dispatch(changeUserIsLoading(false));
		}
	};

export const getUserData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeUserIsLoading(true));
			const userId: string | null = VaultService.getItem<string>(VAULT_SERVICE_KEYS.USER_ID);
			if (!userId) {
				throw new Error(USER_MESSAGE.USER_NOT_FOUND);
			}
			const response: AxiosResponse<any> = await UserService.getById(userId);
			dispatch({ type: ACTION_TYPES.USER_GET_DATA, payload: response.data });
		} catch (error) {
			await Promise.reject(error);
		} finally {
			dispatch({ type: ACTION_TYPES.USER_LOADING, payload: false });
		}
	};

export const signupUser =
	(reqBody: IUserRequest) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeUserIsLoading(true));
			const response: AxiosResponse<ILoginResponse> = await UserService.signup(reqBody);
			setUserDataToStorage(response.data);
			dispatch({ type: ACTION_TYPES.USER_GET_DATA, payload: response.data.userId });
			toastifyMessage(AUTH_MESSAGE.SIGN_UP_SUCCESS, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			if (error.response.status === 422) {
				toastifyMessage(USER_MESSAGE.USER_EXISTS, TOASTIFY_TYPES.ERROR);
				return;
			}
			toastifyMessage(error?.response?.data, TOASTIFY_TYPES.ERROR);
		} finally {
			dispatch(changeUserIsLoading(false));
		}
	};

export const newBulk =
	(reqBody: { [p: string]: any; bulkImage: string | null }) =>
	async (dispatch: Dispatch): Promise<void> => {
		console.log('newBulk');
		try {
			const userId: string | null = VaultService.getItem<string>(VAULT_SERVICE_KEYS.USER_ID);
			if (!userId) {
				throw new Error(USER_MESSAGE.USER_NOT_FOUND);
			}
			const response: AxiosResponse<any> = await UserService.sendBulk(reqBody, userId);

			dispatch({ type: ACTION_TYPES.USER_SEND_BULK_MESSAGE, payload: response });

			toastifyMessage(USER_MESSAGE.BULK_SEND, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			toastifyMessage(USER_MESSAGE.BULK_SEND_ERROR, TOASTIFY_TYPES.ERROR);

			throw new Error(error);
		}
	};
