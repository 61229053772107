export enum TOASTIFY_TYPES {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
	DEFAULT = 'default',
}

export enum COMMON_MESSAGE {
	SOMETHING_WENT_WRONG = 'Something went wrong',
	SUCCESS = 'Success',
	NEW_SCENARIO = 'New scenario created successful',
	SCENARIO_EDIT = 'Scenario edited successful',
	POST_ACTIVE = 'New active scenario seted successful',
	DELETE_SCENARIO = 'Scenario was deleted',
	IMAGE = 'Image downloaded successful',
	IMAGE_DELETE = 'Image deleted successful',
	DELETE_INTRO = 'The intro is required',
	BOT_NOT_SETTED = 'Please set up your bot',
}

export enum USER_MESSAGE {
	USER_NOT_FOUND = 'User not found',
	PASSWORD_IS_WRONG = 'Password is wrong',
	ACCESS_EXPIRED = 'Access-exipred',
	USER_EXISTS = 'User is already exists',
	RESTORE_PASSWORD_SUCCESS = 'Password restore successfully.',
	BULK_SEND = 'New bulk send successfully.',
	BULK_SEND_ERROR = 'New bulk send error.',
}

export enum AUTH_MESSAGE {
	RESET_PASSWORD_SUCCESS = 'Password restore successfully.',
	LOGIN_SUCCESS = 'Login is success',
	LOGOUT_SUCCESS = 'Logout is success',
	SIGN_UP_SUCCESS = 'Account creation successful',
}
