/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { COMMON_MESSAGE, TOASTIFY_TYPES } from 'utils/constants';
import { toastifyMessage } from 'utils/helpers';
import { ScenarioService } from 'utils/services';
import { IBotScenario } from 'utils/types';

export enum ACTION_TYPES {
	SCENARIO_LOADING = 'SCENARIO_LOADING',
	FAVORITE_LOADING = 'FAVORITE_LOADING',
	SCENARIO_GET_DATA = 'SCENARIO_GET_DATA',
	FAVORITE_SCENARIO = 'FAVORITE_SCENARIO',
}

export const changeScenarioIsLoading = (payload: boolean): AnyAction => ({
	type: ACTION_TYPES.SCENARIO_LOADING,
	payload,
});
export const changeFavoriteIsLoading = (payload: boolean): AnyAction => ({
	type: ACTION_TYPES.FAVORITE_LOADING,
	payload,
});

export const getAllScenarioData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeScenarioIsLoading(true));
			const response: AxiosResponse<IBotScenario> = await ScenarioService.getAllScenarios();
			dispatch({ type: ACTION_TYPES.SCENARIO_GET_DATA, payload: response.data });
		} catch (error) {
			await Promise.reject(error);
		} finally {
			dispatch({ type: ACTION_TYPES.SCENARIO_LOADING, payload: false });
		}
	};

export const postActiveScenario =
	(reqBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeFavoriteIsLoading(true));
			await ScenarioService.postActiveScenario(reqBody);
			const response: AxiosResponse<IBotScenario> = await ScenarioService.getAllScenarios();
			dispatch({ type: ACTION_TYPES.SCENARIO_GET_DATA, payload: response.data });
			toastifyMessage(COMMON_MESSAGE.SUCCESS, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			await Promise.reject(error);
		} finally {
			dispatch(changeFavoriteIsLoading(false));
		}
	};

export const postNewScenario =
	(reqBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeScenarioIsLoading(true));
			await ScenarioService.postNewScenario(reqBody);
			const response: AxiosResponse<IBotScenario> = await ScenarioService.getAllScenarios();
			dispatch({ type: ACTION_TYPES.SCENARIO_GET_DATA, payload: response.data });
			toastifyMessage(COMMON_MESSAGE.NEW_SCENARIO, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			await Promise.reject(error);
		} finally {
			dispatch({ type: ACTION_TYPES.SCENARIO_LOADING, payload: false });
		}
	};

export const updateScenario =
	(reqBody: any, id: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeScenarioIsLoading(true));
			await ScenarioService.updateScenario(reqBody, id);
			const response: AxiosResponse<IBotScenario> = await ScenarioService.getAllScenarios();
			dispatch({ type: ACTION_TYPES.SCENARIO_GET_DATA, payload: response.data });
			toastifyMessage(COMMON_MESSAGE.SCENARIO_EDIT, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			await Promise.reject(error);
		} finally {
			dispatch({ type: ACTION_TYPES.SCENARIO_LOADING, payload: false });
		}
	};

export const deleteScenario =
	(reqBody: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(changeScenarioIsLoading(true));
			await ScenarioService.deleteScenario(reqBody);
			const response: AxiosResponse<IBotScenario> = await ScenarioService.getAllScenarios();
			dispatch({ type: ACTION_TYPES.SCENARIO_GET_DATA, payload: response.data });
			toastifyMessage(COMMON_MESSAGE.DELETE_SCENARIO, TOASTIFY_TYPES.SUCCESS);
		} catch (error) {
			await Promise.reject(error);
		} finally {
			dispatch({ type: ACTION_TYPES.SCENARIO_LOADING, payload: false });
		}
	};
