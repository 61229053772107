export class VaultService {
	static storage: Storage = window.localStorage;

	static getItem: <T>(key: string) => T | null = (key: string) => {
		const data: string | null = this.storage.getItem(key);
		if (typeof data === 'string' || data === null) {
			return data;
		}
		return JSON.parse(data);
	};

	static setItem = (key: string, data: unknown) => {
		this.storage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data));
	};

	static deleteItem = (key: string) => {
		this.storage.removeItem(key);
	};

	static clear = () => {
		this.storage.clear();
	};
}
