/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { withField } from 'hoc';
import 'react-quill/dist/quill.snow.css';

interface CustomQuillComponentProps {
	value: any;
	form: any;
	field: any;
}

const CustomQuillComponent: FC<CustomQuillComponentProps> = ({ value, form, field }) => {
	const handleChange = useCallback(
		(content: string) => {
			form.setFieldValue(field?.name, content);
		},
		[form, field?.name]
	);

	const handleBlur = useCallback(() => {
		form.setFieldTouched(field?.name, true);
	}, [form, field?.name]);

	return (
		<ReactQuill
			theme="snow"
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			modules={{
				toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
			}}
		/>
	);
};

export const CustomQuill = withField(CustomQuillComponent);
