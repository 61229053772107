import { IOptionAdvanced } from '../types/options.interface';
import { SELECT_TYPE } from './select-status';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const STATUS_SELECT_OPTIONS: IOptionAdvanced<any>[] = [
	{
		title: 'Active',
		value: SELECT_TYPE.ACTIVE,
		viewVariant: SELECT_TYPE.ACTIVE,
	},
	{
		title: 'Not Active',
		value: SELECT_TYPE.NOT_ACTIVE,
		viewVariant: SELECT_TYPE.NOT_ACTIVE,
	},
];
