import axios, { AxiosResponse } from 'axios';
import { AUTH_API_URL } from 'utils/constants/api-url';
import { VAULT_SERVICE_KEYS } from 'utils/constants/vault-service';
import { VaultService } from 'utils/services/vault.service';
import { ILoginResponse } from 'utils/types';

export const refreshToken = async (): Promise<void> => {
	try {
		const refreshToken = VaultService.getItem<string>(VAULT_SERVICE_KEYS.REFRESH_TOKEN);
		const response: AxiosResponse<Omit<ILoginResponse, 'user'>> = await axios.post(
			`${process.env.REACT_APP_API_URL}/${AUTH_API_URL.REFRESH}`,
			{ refreshToken }
		);
		VaultService.setItem(VAULT_SERVICE_KEYS.ACCESS_TOKEN, response.data.accessToken);
		VaultService.setItem(VAULT_SERVICE_KEYS.REFRESH_TOKEN, response.data.refreshToken);
	} catch (error) {
		VaultService.deleteItem(VAULT_SERVICE_KEYS.ACCESS_TOKEN);
		VaultService.deleteItem(VAULT_SERVICE_KEYS.REFRESH_TOKEN);
		throw error;
	}
};
