export enum ROUTES {
	MAIN = '/',
	NEW_SCENARIO = 'new-scenario',
	SETTINGS = 'settings',
	BULK_SEND = 'bulk-send',
	UPDATE = 'update',

	// AUTH
	LOGIN = 'login',
	RESTORE_PASSWORD = 'password-restore',
	SIGN_UP = 'sign-up',

	NOT_FOUND_PAGE = '*',
}
