export enum AUTH_API_URL {
	LOGIN = 'auth/login',
	LOGOUT = 'auth/logout',
	REFRESH = 'auth/refresh',
	REGISTER = 'auth/signup',
	RESET_PASSWORD = 'auth/reset-password',
}

export enum USER_API_URL {
	MAIN = 'user',
	BULK = 'user/bulk-send/',
}

export enum SCENARIO_API_URL {
	ALL_SCENARIOS = 'scenarios/',
	BY_ID = 'scenario/',
	FAVORITE_SCENARIO = 'favorite-scenario/',
}

export enum SETTINGS_API_URL {
	MAIN = 'user/bot/',
}

export enum MULTIMEDIA_API_URL {
	UPLOAD_FILE = 'multimedia',
}
